import { CheckboxCard } from '@gnist/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { useGetApiLocaleConfig } from '../../../../_api/apiLocale';
import { useGetRecommendedServices } from '../../../../_api/http/services';
import { useLanguageContext } from '../../../../lib/languages/languageContext';
import { Price } from '../../../shared/prices/Price';
import { ServiceDescription } from '../../../shared/services/ServiceDescription';
import { useStandardServicesForm } from '../../useSectionForm';
import { useStandardServiceListValidity } from '../../useStandardServiceListValidity';
import { ProvideMileage } from './ProvideMileage';
import { RecommendedServices } from './RecommendedServices';
import { preselectRecommended } from './preselectRecommendedServices';
import { useRefetchRecommendedService } from './useRefetchRecommendedService';

type RecommendedServicesContainerProps = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    services: ServiceViewModel[];
    vin: string;
    dealerNumber: string;
    recommendedServices: ReturnType<typeof useGetRecommendedServices>;
};

export function removeRecommendedServices(services: ServiceViewModel[]) {
    return services.filter((x) => x.category !== 'RecommendedService');
}

export function RecommendedServicesContainer({
    serviceForm,
    services,
    vin,
    recommendedServices,
    dealerNumber,
}: RecommendedServicesContainerProps) {
    const setRecommendedServiceState =
        serviceForm.setValue('recommendedService');
    const setSelectedServiceIds = serviceForm.setValue('selectedIds');
    const isOpen = serviceForm.state.raw.recommendedService.open;
    const selectedServiceIds = serviceForm.state.raw.selectedIds;

    const mileage = serviceForm.state.raw.recommendedService.mileage;

    const validity = serviceForm.inputProps('selectedIds').validity;
    const serviceListValidity = useStandardServiceListValidity(validity);

    const { data } = recommendedServices;
    const [lc] = useLanguageContext();

    const hasRecommendedServices = services.some(
        (x) => x.category === 'RecommendedService'
    );

    const hasSelectedRecService = selectedServiceIds.some((x) =>
        recommendedServices?.data?.some((y) => x === y.id)
    );

    const { countryCode } = useGetApiLocaleConfig();
    const preselect = preselectRecommended(
        services,
        selectedServiceIds,
        setSelectedServiceIds,
        setRecommendedServiceState,
        mileage
    );
    useRefetchRecommendedService(
        vin,
        mileage,
        countryCode,
        dealerNumber,
        preselect,
        recommendedServices,
        hasSelectedRecService
    );

    if (!hasRecommendedServices) {
        return null;
    }

    return (
        <CheckboxCard
            onChange={() => {
                const recServiceIds = data
                    ?.filter((x) => x.category === 'RecommendedService')
                    .map((x) => x.id);
                if (isOpen) {
                    setSelectedServiceIds(
                        selectedServiceIds.filter(
                            (x) => !recServiceIds?.includes(x)
                        )
                    );
                } else if (!isOpen && recServiceIds) {
                    setSelectedServiceIds([
                        ...selectedServiceIds,
                        ...recServiceIds,
                    ]);
                }
                setRecommendedServiceState({ open: !isOpen, mileage });
            }}
            density="compact"
            label={
                lc.standardServices.custom_service_groups
                    .recommended_group_title
            }
            description={
                <ServiceDescription
                    selected={isOpen}
                    description={
                        lc.standardServices.custom_service_groups
                            .recommended_group_description
                    }
                >
                    {!isOpen && <Price values={[]} isCalculated />}
                </ServiceDescription>
            }
            value={isOpen}
            {...serviceListValidity}
        >
            {isOpen && (
                <>
                    <ProvideMileage
                        dealerNumber={dealerNumber}
                        recommendedServices={recommendedServices}
                        vin={vin}
                        serviceForm={serviceForm}
                        preselectRecommendedServices={preselect}
                    />
                    <RecommendedServices
                        recommendedServices={data ?? []}
                        selectedServiceIds={serviceForm.state.raw.selectedIds}
                        serviceListValidity={serviceListValidity}
                        setSelectedServices={setSelectedServiceIds}
                        selectedDealerNumber={dealerNumber}
                    />
                </>
            )}
        </CheckboxCard>
    );
}
