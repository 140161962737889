import { datadogRum } from '@datadog/browser-rum';
import {
    ExistingCarRelation,
    RecallAppointment,
} from 'external-apis/src/types/bilhold';
import { useEffect } from 'react';
import { CardLayout } from '../../components/CardLayout';
import { SuggestedActionCard } from '../../components/HomeStyledComponents';
import service from '../eu-control/eu-control.png';

interface UsedCarValuationCardProps {
    toggleDrawer: () => void;
    car: ExistingCarRelation;
    recall: RecallAppointment;
}

export const RecallServiceCard = ({
    toggleDrawer,
    car,
    recall,
}: UsedCarValuationCardProps) => {
    useEffect(() => {
        datadogRum.addAction('User sees recall card');
    }, []);
    return (
        <SuggestedActionCard
            clickName={`Recall card for ${recall?.recallCode}`}
            toggleDrawer={toggleDrawer}
        >
            <CardLayout
                title={'Tilbakekallingsoppgradering'}
                body={`Bestill time for tilbakekallingsoppgradering ${recall?.recallCode} for din ${car?.name} i dag`}
                ImageSrc={service}
            />
        </SuggestedActionCard>
    );
};
