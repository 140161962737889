import { ServiceViewModel } from 'external-apis/src/types/port';
import { existingInfo } from '../standard-services/AdditionalServiceInfo';
import { StdServicesForm } from '../standard-services/useSectionForm';
import { StandardServicesData } from '../standard-services/useStandardServices';

export function toSelectedService(
    service: ServiceViewModel,
    additionalInfo?: StdServicesForm['additionalInfo']
): StandardServicesData['selectedServices'][number] {
    const info = additionalInfo?.find(
        existingInfo({
            adapterId: service.adapterId,
            serviceId: service.id,
        })
    );
    return {
        serviceId: service.id,
        adapterId: service.adapterId,
        serviceType: service.serviceType,
        name: service.name,
        additionalInfo: service.additionalInfo,
        dealerSpecificInformation: service.dealerSpecificInformation,
        serviceCategory: service.category,
        bookingType: service.bookingType,
        comment: info?.comment,
    };
}
