import { ActionCard, DetailText, breakpointTablet } from '@gnist/design-system';
import { styled } from 'styled-components';
import { Vehichle } from '../../../_api/http/vehicle';
import { BoldText } from '../../../components/BoldText';
import { VehicleSectionState } from '../ChooseVehicleSection';
import { Brand } from 'external-apis/.typescript/src/types/bilhold';

type ChooseRegisteredVehicleProps = {
    setSectionState: (x: VehicleSectionState) => void;
    registeredVehicles: Vehichle[];
};

const RegisteredCarsLayout = styled.div`
    display: grid;
    @media (min-width: ${breakpointTablet}) {
        grid-template-columns: repeat(2, 1fr);
    }
    gap: var(--moller-spacing-xxs);
`;

function formatBrand(brand: Brand) {
    switch (brand) {
        case 'VolkswagenCommercialVehicles':
            return 'VOLKSWAGEN';
        default:
            return brand.toUpperCase();
    }
}

export const ChooseRegisteredVehicle: React.FC<
    ChooseRegisteredVehicleProps
> = ({ setSectionState, registeredVehicles }) => {
    const registeredVehicleCards = registeredVehicles.map((car) => (
        <ActionCard
            // Ensure plateNumber is not included in datadog RUM action name
            data-dd-action-name={`${car.brand}`}
            key={car.vin}
            density="compact"
            onClick={() =>
                setSectionState({
                    viewMode: 'done',
                    data: { registrationNumber: car.licensePlate },
                })
            }
        >
            <BoldText>
                {formatBrand(car.brand)} {car.model}
            </BoldText>
            <DetailText>{car.licensePlate}</DetailText>
        </ActionCard>
    ));

    return (
        <RegisteredCarsLayout>{registeredVehicleCards}</RegisteredCarsLayout>
    );
};
