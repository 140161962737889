import {
    AlertBanner,
    Heading1Text,
    PrimaryButton,
    Row,
    TextContainer,
    TextField,
} from '@gnist/design-system';
import { AxiosError, HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { OtpStep } from 'src/features/create-new-user/utils/OtpStep';
import { useCheckUserForRegistration } from 'src/utils/auth/checkUserForRegistration';
import { STORAGE_KEY_OVERRIDE_ENVIRONMENT } from 'src/utils/auth/constants';
import enrollUser, { EnrollmentError } from 'src/utils/auth/enrollUser';
import { validPhoneNumber } from 'src/utils/auth/otpFormValidation';
import LocalStorage from 'src/utils/browser/LocalStorage';
import { environmentNameFromIndex } from 'src/utils/environment/environments';
import { ENROLL_NEW_USER } from 'src/utils/http/endpoints';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import {
    PhoneNumberFormatValidity,
    ValidInput,
    WrongOtpValidity,
} from '../utils/enrollmentValidity';
import { invalidPhoneNumber } from '../utils/invalidPhoneNumber';

export interface OneTimePasswordFormProps {
    onTokenSuccess: (token: string) => void;
    phoneNumber: string;
    setPhoneNumber: (phoneNumber: string) => void;
    showUserExistsModal: (value: boolean) => void;
}

const RowWithWrap = styled(Row)`
    flex-wrap: wrap;
    padding-top: var(--moller-spacing-s);
    padding-bottom: var(--moller-spacing-s);
`;

export const OneTimePasswordForm: React.FC<OneTimePasswordFormProps> = ({
    onTokenSuccess,
    phoneNumber,
    setPhoneNumber,
    showUserExistsModal,
}: OneTimePasswordFormProps) => {
    const [otpCode, setOtpCode] = useState('');
    const [otpStep, changeOtpStep] = useState<OtpStep>(OtpStep.PHONE_NUMBER);
    const [otpError, setOtpError] = useState(false);
    const [requestOtpError, setRequestOtpError] = useState('');
    const [phoneNumberInvalidError, setPhoneNumberInvalidError] =
        useState(false);

    const {
        mutate: checkUserForRegistration,
        isError: isOtpError,
        isSuccess: isOtpSuccess,
        data,
    } = useCheckUserForRegistration();

    const requestOtp = () =>
        enrollUser(ENROLL_NEW_USER, phoneNumber)
            .then(() => {
                changeOtpStep(OtpStep.CONFIRM);
            })
            .catch((error: AxiosError<EnrollmentError>) => {
                const errorResponse = error.response?.data;

                if (
                    errorResponse?.httpCode === HttpStatusCode.TooManyRequests
                ) {
                    setRequestOtpError('please_wait_before_trying_again');
                    changeOtpStep(OtpStep.CONFIRM);
                } else {
                    setRequestOtpError('something_went_wrong');
                }
            });

    const stepConfigurations = {
        [OtpStep.PHONE_NUMBER]: {
            buttonText: 'sendOneTimePassword',
            onButtonClick: () => setPhoneNumberInvalidError(true),
        },
        [OtpStep.SEND_CODE]: {
            buttonText: 'sendOneTimePassword',
            onButtonClick: () => requestOtp(),
        },
        [OtpStep.CONFIRM]: {
            buttonText: 'confirmOneTimePassword',
            onButtonClick: () => {
                if (otpCode) {
                    checkUserForRegistration({ phoneNumber, otpCode });
                }
            },
        },
    } as const;

    const step = stepConfigurations[otpStep];

    useEffect(() => {
        if (isOtpSuccess) {
            if (data.data.partyId) {
                showUserExistsModal(true);
            } else {
                onTokenSuccess(data.data.otp);
            }
        }
        if (isOtpError) {
            setOtpError(true);
        }
    }, [data, isOtpError, isOtpSuccess, onTokenSuccess, showUserExistsModal]);

    useEffect(() => {
        if (validPhoneNumber(phoneNumber)) {
            changeOtpStep(OtpStep.SEND_CODE);
        } else {
            changeOtpStep(OtpStep.PHONE_NUMBER);
            setOtpCode('');
            setOtpError(false);
        }
        // Override the environment in the app by typing ###1### for staging and ###2### for test
        const environmentSelectorPattern = /^###\d###$/;
        if (isMobileApp() && environmentSelectorPattern.test(phoneNumber)) {
            const envIndex = parseInt(phoneNumber[3], 10);
            const envOverrideName = environmentNameFromIndex(envIndex);
            LocalStorage.set(STORAGE_KEY_OVERRIDE_ENVIRONMENT, envOverrideName);
            alert(`Using environment ${envIndex} (${envOverrideName})`);
        }
    }, [phoneNumber]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                void step.onButtonClick();
            }}
        >
            <Heading1Text>{lang.register_new_user}</Heading1Text>
            <TextContainer>
                <p>{lang.createUserDescriptionText}</p>
            </TextContainer>
            <RowWithWrap gap="s">
                <TextField
                    autoFocus={!phoneNumber}
                    label={lang.cellPhoneNumber}
                    value={phoneNumber}
                    autoComplete="tel-national"
                    prefix="+47"
                    type="tel"
                    onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        setOtpCode('');
                        setOtpError(false);
                        setRequestOtpError('');
                        setPhoneNumberInvalidError(false);
                    }}
                    validity={
                        phoneNumberInvalidError ||
                        invalidPhoneNumber(phoneNumber)
                            ? PhoneNumberFormatValidity
                            : ValidInput
                    }
                />
                {otpStep === OtpStep.CONFIRM && (
                    <TextField
                        autoFocus={otpStep === OtpStep.CONFIRM}
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        label={'Engangskode'}
                        value={otpCode}
                        onChange={(e) => {
                            setOtpCode(e.target.value);
                            setOtpError(false);
                            setRequestOtpError('');
                        }}
                        validity={otpError ? WrongOtpValidity : ValidInput}
                    />
                )}
            </RowWithWrap>

            <PrimaryButton type="submit">
                {step.buttonText && lang[step.buttonText]}
            </PrimaryButton>

            {requestOtpError &&
                (requestOtpError === 'something_went_wrong' ? (
                    <AlertBanner
                        type={'error'}
                        message={lang.something_went_wrong}
                    />
                ) : (
                    <AlertBanner
                        type={'error'}
                        message={lang.please_wait_before_trying_again}
                    />
                ))}
        </form>
    );
};
