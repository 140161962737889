import { BookingViewModel } from 'src/features/appointment/api/useAppointments';
import { formatDate } from '../data';

const getFutureAppointment = (): BookingViewModel => {
    return {
        vin: 'TMBJC9NE2F0015998',
        registrationNumber: 'BS96267',
        isCancellable: false,
        isEditable: false,
        dealer: {
            name: 'Møller Bil Etterstad',
            streetAddress: 'Grenseveien 67',
            phoneNumber: '24032260',
            city: 'OSLO',
            zipCode: '0663',
            coordinates: {
                latitude: 59.91830825805664,
                longitude: 10.795306205749512,
            },
            id: '083',
            email: 'etterstad@moller.no',
            countryCode: 'NOR',
            dealerTypes: ['Service'],
            serviceCertifications: ['Audi', 'Skoda', 'Vw'],
            dealerInformation: [],
        },
        startTime: `${formatDate(
            new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 25)
        )}T12:00`,
        estimatedFinished: `${formatDate(
            new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 26)
        )}T12:00`,
        id: '5d7ae20e-5abb-4ec4-82de-21363167dae8',
        bookedServices: [
            {
                name: 'EU-kontroll er en lovpålagt godkjenning av bilen din.',
                id: '3',
                price: {
                    fixedPrice: 999,
                    currency: 'Nok',
                },
            },
        ],
        adapterId: '',
    };
};

export default getFutureAppointment;
