import { datadogRum } from '@datadog/browser-rum';
import {
    Heading1Text,
    LeadText,
    Link,
    TextContainer,
} from '@gnist/design-system';
import {
    ExistingCarRelation,
    RecallAppointment,
} from 'external-apis/src/types/bilhold';
import { useEffect } from 'react';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import service from '../eu-control/eu-control.png';

const FlexContainer = styled.div<{ reverseOrder: boolean }>`
    display: flex;
    flex-direction: ${({ reverseOrder }) =>
        reverseOrder ? 'column-reverse' : 'column'};

    h2 {
        margin-top: var(--moller-spacing-s) !important;
    }
`;

interface RecallServiceContentProps {
    car: ExistingCarRelation;
    recall: RecallAppointment;
}

export const RecallServiceContent = ({
    car,
    recall,
}: RecallServiceContentProps) => {
    useEffect(() => {
        datadogRum.addAction('User sees recall article');
    }, []);

    const selectAudiBatteryIssueLink = () => {
        if (recall.recallCode === '93V2') {
            return 'https://m.dm.audi.no/rest/head/mirrorPage/@-ytSCxbVXQPiszlFP2cSPYCHB3VGi5Ko5I8Y5yyHI5yFbaMVhRvRvBQRxclz8Q3t94KoULq8SJ62_P8ZWG-yVCKhGS57df2orpAMEVwTSIRR6Xd3.html';
        } else {
            return 'https://m.dm.audi.no/rest/head/mirrorPage/@-ytSCxbVXQPiszlFP2cSPWk5ru5ghZ1gYkaONjxTply0bJrOiQlB4u7xaI8_JZO7e77ZjTwbJMbGgTPHxdBFIR8faL5WnsmUShAI2Mb3Zu2fPqOI.html';
        }
    };

    const renderAudiBatteryIssue = () => {
        return (
            <TextContainer>
                <FlexContainer reverseOrder={isMobileApp()}>
                    {!isMobileApp() && (
                        <img src={service} alt="Tilbakekalling" />
                    )}
                    <Heading1Text>{`Tilbakekallingsoppgradering ${recall?.recallCode} for kontroll av høyenergibatteri`}</Heading1Text>
                </FlexContainer>
                <p>
                    Din bil er berørt av denne tilbakekallingen. Bestill time i
                    Bilhold, eller kontakt en Audi-forhandler så raskt som mulig
                    for å avtale en verkstedtime. Du kan kjøre bilen frem til du
                    får time på verksted.
                </p>
                <p>
                    <Link
                        href={selectAudiBatteryIssueLink()}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Vennligst les dette skrivet nøye
                    </Link>
                </p>
                <p>
                    Oppgraderingen er kostnadsfri. Kontrollen tar en dag og hvis
                    noe må byttes tar det to til tre dager.
                </p>
                <p>
                    <b>
                        Vi ber deg om å begrense batteriladingen til maksimalt
                        80 prosent frem til bilen har vært på verksted.
                    </b>
                </p>
                <p>
                    Har du solgt bilen, eller om den ikke lenger er i bruk, ber
                    vi deg ta kontakt med vårt{' '}
                    <Link href="mailto:kundeservice@moller.no">
                        kundesenter på e-post.
                    </Link>
                </p>
            </TextContainer>
        );
    };

    const tannstangStyringIssue = () => {
        return (
            <TextContainer>
                <FlexContainer reverseOrder={isMobileApp()}>
                    {!isMobileApp() && (
                        <img src={service} alt="Tilbakekalling" />
                    )}
                    <Heading1Text>{`Tilbakekallingsoppgradering ${recall?.recallCode}`}</Heading1Text>
                </FlexContainer>
                <LeadText>
                    Av hensyn til sikkerheten er det nødvendig å bytte ut
                    tannstangstyringen på bilen din.
                </LeadText>
                <p>
                    {`
                    Fabrikk har registrert at det på ${car.name} fra et bestemt
                    produksjonstidsrom kan være montert en tannstangstyring med
                    utilstrekkelig korrosjonsbeskyttelse. Dette medfører en risiko
                    for at det etter hvert kan oppstå kraftig korrosjon på hele
                    tannstangstyringen.`}
                </p>
                <p>
                    Avhengig av korrosjonsgraden kan det oppstå sprekker og
                    materialbrudd på huset til tannstangstyringen. Dette
                    medfører en risiko for at tannstangen mister grepet på
                    tannhjulet. Dette kan føre til at styringen ikke virker. Det
                    er fare for ulykker. Bestill time for oppgradring så fort
                    som mulig. Vi håper du har forståelse for at dette
                    verkstedbesøket er nødvendig og beklager de ulemper dette
                    eventuelt medfører.
                </p>
            </TextContainer>
        );
    };

    if (
        car.brand === 'Audi' &&
        (recall.recallCode === '93V2' || recall.recallCode === '93U9')
    ) {
        return renderAudiBatteryIssue();
    } else {
        return tannstangStyringIssue();
    }
};
