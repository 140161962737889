import { useGetCustomer } from '../../_api/http/customer';
import { useGetDealers } from '../../_api/http/dealers';
import {
    useGetRecommendedServices,
    useGetServices,
} from '../../_api/http/services';
import { useGetVehicle } from '../../_api/http/vehicle';
import { Done } from '../sectionUtils';
import { StandardServicesDependencies } from './useStandardServices';

export function useFetchedData(
    dependencies: Done<StandardServicesDependencies | undefined>
) {
    const vehicleResponse = useGetVehicle(
        dependencies?.chooseVehicle.data.registrationNumber
    );
    const vin = vehicleResponse.data?.vin;
    const dealerNumbers = dependencies && [
        dependencies.chooseDealer.data.dealerNumber,
    ];
    const customerResponse = useGetCustomer();
    const dealerResponse = useGetDealers();
    const serviceResponse = useGetServices({
        vin,
        dealerNumbers,
    });
    const recommendedServices = useGetRecommendedServices();

    return {
        vehicleResponse,
        dealerResponse,
        customerResponse,
        serviceResponse,
        recommendedServices,
    };
}
