import {
    BookingCreateModelQuery,
    BookingType,
    ReservedTimeSlotViewModel,
} from 'external-apis/src/types/port';
import { StandardServicesData } from '../../standard-services/useStandardServices';

type CreateBookingBody = {
    adapterId: string;
    businessOperation: ReturnType<typeof getBusinessOperation>;
    bookingId: string;
    vin: string;
    dealerNumber: string;
    startTime: string | undefined;
    bookedServices: ReturnType<typeof toBookedService>[];
    mileage: string;
    isWaiting: boolean;
    bookingType: BookingType;
};

export function createBookingBody({
    adapterId,
    bookingId,
    vin,
    bookedServices,
    businessOperation,
    mileage,
    dealerNumber,
    startTime,
    isWaiting,
    bookingType,
}: CreateBookingBody): BookingCreateModelQuery {
    return {
        adapterId,
        bookingId,
        vin,
        services: bookedServices,
        mileage: mileage === '' ? null : +mileage,
        businessOperation,
        dealerNumber,
        startTime: startTime,
        customerWaitingAtDealer: isWaiting,
        countryCode: 'NOR',
        type: bookingType,
    };
}

export function toBookedService(
    selectedService: StandardServicesData['selectedServices'][number]
) {
    return {
        serviceId: selectedService.serviceId,
        additionalInfo: selectedService.comment
            ? [{ comment: selectedService.comment }]
            : [],
    };
}

export function getBusinessOperation(
    reservedTimeslot: ReservedTimeSlotViewModel | undefined,
    selectedDealerNumbner: string,
    selectedServices: StandardServicesData['selectedServices']
) {
    if (reservedTimeslot) {
        return reservedTimeslot.businessOperation;
    }
    const selectedDealerSpecificInfo =
        selectedServices[0].dealerSpecificInformation.find(
            (d) => d.dealerNumber === selectedDealerNumbner
        );
    return selectedDealerSpecificInfo?.businessOperation ?? 'unknown';
}
