import { AlertBanner, GhostButton, Row, TextField } from '@gnist/design-system';
import { stripWhitespace } from '@gnist/design-system/utilities/validation';
import { styled } from 'styled-components';
import { useGetApiLocaleConfig } from '../../../../_api/apiLocale';
import { useGetRecommendedServices } from '../../../../_api/http/services';
import { useLanguageContext } from '../../../../lib/languages/languageContext';
import { useStandardServicesForm } from '../../useSectionForm';

type ProvideMileageProps = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    vin: string;
    dealerNumber: string;
    recommendedServices: ReturnType<typeof useGetRecommendedServices>;
    preselectRecommendedServices: (serviceIds: string[]) => void;
};

const MileageButton = styled(GhostButton)`
    margin-top: var(--moller-spacing-xxs);
`;

const RecommendedServiceError = styled(AlertBanner)`
    margin-top: var(--moller-spacing-xxs);
`;

export function ProvideMileage({
    serviceForm,
    vin,
    recommendedServices,
    dealerNumber,
    preselectRecommendedServices,
}: ProvideMileageProps) {
    const mileageValidity =
        serviceForm.inputProps('recommendedService').validity;

    const { mutate, isPending: isLoading, error } = recommendedServices;

    const setRecommendedServiceState =
        serviceForm.setValue('recommendedService');
    const mileage = serviceForm.state.raw.recommendedService.mileage;

    const isOpen = serviceForm.state.raw.recommendedService.open;
    const { countryCode } = useGetApiLocaleConfig();

    const [lc] = useLanguageContext();
    const isValid = mileageValidity === undefined;
    return (
        <>
            <p>{lc.standardServices.mileage_description}</p>
            <Row style={{ gap: `0 var(--moller-spacing-xxs)` }} flexWrap="wrap">
                <TextField
                    label={lc.standardServices.mileage}
                    validity={mileageValidity}
                    value={mileage}
                    helperText={lc.standardServices.mileage_helper_text}
                    onChange={(ev) => {
                        return setRecommendedServiceState({
                            mileage: stripWhitespace(ev.target.value),
                            open: isOpen,
                        });
                    }}
                    onKeyDown={(e) => {
                        if (
                            isValid &&
                            (e.key?.toLowerCase() === 'enter' ||
                                e.code?.toLocaleLowerCase() === 'enter')
                        ) {
                            mutate({
                                body: {
                                    vin,
                                    mileage: parseInt(mileage),
                                    countryCode,
                                    dealerFilter: {
                                        selectedDealerNumbers: [dealerNumber],
                                    },
                                },
                                preselectRecommendedServices,
                            });
                        }
                    }}
                />
                <MileageButton
                    onClick={() => {
                        if (isValid) {
                            return mutate({
                                body: {
                                    vin,
                                    mileage: parseInt(mileage),
                                    countryCode,
                                    dealerFilter: {
                                        selectedDealerNumbers: [dealerNumber],
                                    },
                                },
                                preselectRecommendedServices,
                            });
                        }
                    }}
                    loading={{
                        isLoading: isLoading,
                        loadingText: 'loading',
                    }}
                >
                    {lc.standardServices.find_recommended_services}
                </MileageButton>
            </Row>
            {!!error && (
                <RecommendedServiceError
                    type="error"
                    heading={lc.errors.recommended_service_error_heading}
                    message={lc.errors.recommended_service_error}
                />
            )}
        </>
    );
}
