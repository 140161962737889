import {
    AlertBanner,
    AllowedIcons,
    DetailText,
    Icon,
    InfoCard,
    Row,
    Heading2Text,
} from '@gnist/design-system';
import { TextSkeleton } from '@gnist/design-system/building-blocks/skeletons';
import { ServiceBookletItemViewModel } from 'external-apis/src/types/port';
import { groupBy, orderBy } from 'lodash';
import lang from 'src/utils/lang';
import { formatMileage } from 'src/utils/string/formatMilage';
import { formatDateDayAndMonth } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';
import { useServiceBooklet } from '../query/useServiceBooklet';

const StyledInfoCard = styled(InfoCard)`
    border-radius: 1rem;
    margin-top: var(--moller-spacing-s);
`;

const StyledYearText = styled(Heading2Text)`
    margin-top: var(--moller-spacing-m);
`;

const StyledRow = styled(Row)`
    margin-top: var(--moller-spacing-base);
`;

const List = styled.ul`
    margin-top: var(--moller-spacing-s);
    padding-left: inherit;
`;

const ListElement = styled.li`
    margin-top: var(--moller-spacing-xs);
`;

const Container = styled.div`
    margin-top: var(--moller-spacing-s);
`;

interface Props {
    vin: string;
}

export function ServiceBooklet({ vin }: Props) {
    const {
        data,
        isPending: isLoading,
        isError,
        error,
    } = useServiceBooklet(vin);

    if (isLoading) {
        return (
            <Container>
                <TextSkeleton width="90px" height="25px" />
                {Array(3)
                    .fill(3)
                    .map((_, index) => (
                        <ServiceBookletItem key={index} isLoading />
                    ))}
            </Container>
        );
    }

    if (isError) {
        return (
            <Container>
                <AlertBanner
                    headingLevel={'h3'}
                    heading={lang.somethingWentWrong}
                    message={error.message}
                    type={'error'}
                />
            </Container>
        );
    }

    if (data.length > 0) {
        const groupedByYearServiceBookletItems = groupBy(data, (item) =>
            new Date(item.date).getFullYear()
        );
        return (
            <>
                {Object.keys(groupedByYearServiceBookletItems)
                    .sort((a, b) => Number(b) - Number(a))
                    .map((year) => (
                        <div key={year}>
                            <StyledYearText>{year}</StyledYearText>
                            {orderBy(
                                groupedByYearServiceBookletItems[year],
                                (item) => new Date(item.date),
                                'desc'
                            )?.map((i) => {
                                return (
                                    <ServiceBookletItem
                                        serviceBookletItem={i}
                                        key={`service-item-${i.dealerNumber}-${i.date}`}
                                    />
                                );
                            })}
                        </div>
                    ))}
            </>
        );
    }

    if (data.length === 0) {
        return (
            <Container>
                <DetailText>{lang.noDataServiceBooklet}</DetailText>
            </Container>
        );
    }
}

interface ServiceBookletItemProps {
    serviceBookletItem?: ServiceBookletItemViewModel;
    isLoading?: boolean;
}
function ServiceBookletItem({
    serviceBookletItem,
    isLoading,
}: ServiceBookletItemProps) {
    return (
        <StyledInfoCard>
            <Row horizontalAlign="spaceBetween">
                <ServiceBookletItemProperty
                    text={
                        serviceBookletItem ? serviceBookletItem.dealerName : ''
                    }
                    isLoading={!!isLoading}
                />
                <ServiceBookletItemProperty
                    icon="calendar"
                    text={
                        serviceBookletItem
                            ? formatDateDayAndMonth(serviceBookletItem.date)
                            : ''
                    }
                    isLoading={!!isLoading}
                />
            </Row>
            <StyledRow gap="m">
                <ServiceBookletItemProperty
                    icon="speed"
                    text={
                        serviceBookletItem
                            ? `${formatMileage(serviceBookletItem.mileage)} km`
                            : ''
                    }
                    isLoading={!!isLoading}
                />
                <ServiceBookletItemProperty
                    icon="schedule"
                    text={
                        serviceBookletItem
                            ? `${serviceBookletItem.vehicleAge} ${lang.carAgeByMonth}`
                            : ''
                    }
                    isLoading={!!isLoading}
                />
            </StyledRow>
            {!serviceBookletItem ? (
                <Container>
                    <TextSkeleton width="175px" height="20px" />
                </Container>
            ) : (
                <List>
                    {serviceBookletItem.serviceJobs.map((job) => (
                        <ListElement key={job.name}>
                            <DetailText>{job.name}</DetailText>
                        </ListElement>
                    ))}
                </List>
            )}
        </StyledInfoCard>
    );
}

interface ServiceBookletItemPropertyProps {
    text: string;
    isLoading: boolean;
    icon?: AllowedIcons;
}

function ServiceBookletItemProperty({
    text,
    isLoading,
    icon,
}: ServiceBookletItemPropertyProps) {
    return (
        <Row verticalAlign="center" gap="base">
            {icon && <Icon icon={icon} />}
            <DetailText>
                {isLoading ? <TextSkeleton width="75px" height="20px" /> : text}
            </DetailText>
        </Row>
    );
}
