import { InputFieldValidity, TextField } from '@gnist/design-system';
import {
    runValidation,
    validRegistrationNumber,
} from '@gnist/design-system/utilities/validation';
import { UseQueryResult } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useGetVehicle } from '../../_api/http/vehicle';
import { getErrorMessage } from '../../lib/form/validationUtils';
import {
    LanguageIdentifier,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { ChooseVehicleLoader } from './ChooseVehicleLoader';
import { VehicleSectionState } from './ChooseVehicleSection';
import { MyVehicles } from './logged-in/MyVehicles';
import { VehicleInfo } from 'external-apis/.typescript/src/types/bilhold';

const RegistrationNumber = styled.div`
    input {
        width: 280px;
        text-transform: uppercase;
    }
`;

interface ChooseVehicleEditProps {
    setSectionState: (x: VehicleSectionState) => void;
}

export const ChooseVehicleEdit: React.FC<ChooseVehicleEditProps> = ({
    setSectionState,
}) => {
    const [lc, { languageId }] = useLanguageContext();
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [validatedRegNr, setValidatedRegNr] = useState<string>();

    const response = useGetVehicle(validatedRegNr);

    useEffect(() => {
        if (validatedRegNr && response.isSuccess) {
            setSectionState({
                viewMode: 'done',
                data: {
                    registrationNumber: validatedRegNr,
                },
            });
        }
    }, [response.isSuccess, setSectionState, validatedRegNr]);

    const onChangeRegistrationNumber = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const validation = runValidation(
            event.target.value,
            undefined,
            validRegistrationNumber
        );
        setValidatedRegNr(validation.isValid ? validation.value : undefined);
        setRegistrationNumber(event.target.value);
    };

    if (response.isLoading && validatedRegNr) {
        return <ChooseVehicleLoader />;
    }

    return (
        <>
            <MyVehicles {...{ setSectionState }} />
            <RegistrationNumber>
                <TextField
                    id="registration-number-form-input"
                    label={lc.chooseVehicle.enter_registration_number}
                    validity={getValidity(
                        registrationNumber,
                        response,
                        languageId
                    )}
                    onChange={onChangeRegistrationNumber}
                />
            </RegistrationNumber>
        </>
    );
};

function getValidity(
    registrationNumber: string,
    response: Omit<UseQueryResult<VehicleInfo, Error>, 'data'>,
    languageId: LanguageIdentifier
): InputFieldValidity<{ errorMsgRequired: true }> {
    const validation = runValidation(
        registrationNumber,
        undefined,
        validRegistrationNumber
    );
    const staticallyInvalid =
        !validation.isValid &&
        validation.showMessageImmediately &&
        validation.errorMessages.length > 0;

    if (staticallyInvalid) {
        return getErrorMessage(validation, languageId);
    }

    if (response.isError) {
        return {
            type: 'error',
            message: response.error?.message ?? '',
        };
    }

    return {
        type: 'none',
    };
}
