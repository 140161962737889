import {
    ExistingCarRelation,
    RecallAppointment,
} from 'external-apis/src/types/bilhold';
import { useState } from 'react';
import { getBookingPath } from 'src/features/booking/usePathToBooking';
import { CampaignDrawer } from 'src/features/home/components/CampaignDrawer';
import { CampaignModal } from 'src/features/home/components/CampaignModal';
import { RecallServiceCard } from 'src/features/home/current-actions/recalls/RecallServiceCard';
import { RecallServiceContent } from 'src/features/home/current-actions/recalls/RecallServiceContent';
import { isMobileApp } from 'src/utils/mobile';
import service from '../eu-control/eu-control.png';

export interface RecallServiceProps {
    car: ExistingCarRelation;
    recalls: RecallAppointment[];
}

export const RecallService = ({ car, recalls }: RecallServiceProps) => {
    const [campaignModalIsOpen, setCampaignModalIsOpen] = useState(false);
    const bookingPath = getBookingPath({
        vehicleIdentificationNumber: car.vin,
        registrationNumber: car.licensePlate,
    });
    //One recall pr brand/model, so one car shouldnt have more then 1 recall
    const recall = recalls.find((r) => r.vin === car.vin);

    if (recall == undefined) {
        return null;
    }

    return (
        <>
            <RecallServiceCard
                car={car}
                recall={recall}
                toggleDrawer={() => {
                    setCampaignModalIsOpen(true);
                }}
            />
            {isMobileApp() ? (
                <CampaignDrawer
                    drawerIsOpen={campaignModalIsOpen}
                    setDrawerIsOpen={setCampaignModalIsOpen}
                    imageUrl={service}
                    title={'Tilbakekallingsoppgradering'}
                    buttonText={'Bestill time'}
                    buttonLink={bookingPath}
                >
                    <RecallServiceContent car={car} recall={recall} />
                </CampaignDrawer>
            ) : (
                <CampaignModal
                    campaignModalIsOpen={campaignModalIsOpen}
                    setCampaignModalIsOpen={setCampaignModalIsOpen}
                    buttonText={'Gå til booking'}
                    buttonLink={bookingPath}
                >
                    <RecallServiceContent car={car} recall={recall} />
                </CampaignModal>
            )}
        </>
    );
};
