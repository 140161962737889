import { Fetcher, Middleware } from 'openapi-typescript-fetch';
import { ReactNode, createContext, useContext } from 'react';
import { Environment } from '../..';
import { paths as bilholdPaths } from '../schema/bilhold-api';
import { paths as carCarePath } from '../schema/carcare';
import { paths as damageAppraisalPaths } from '../schema/damage-appraisal';
import { paths as portPaths } from '../schema/port';
import { paths as userSelfServicePaths } from '../schema/user-self-service';
import { configureBilholdFetcher } from './bilhold';
import { configureCarCareFetcher } from './carcare';
import { configureDamageAppraisalFetcher } from './damageappraisal';
import { configurePortFetcher } from './port';
import { configureUserSelfServiceFetcher } from './user-self-service';

const FetchersContext = createContext<Fetchers | null>(null);

export type FetcherConfiguration = {
    environment: Environment;
    middlewares?: Middleware[];
    localEnvironmentOverride?: string;
};

type Fetchers = {
    port: {
        baseUrl: string;
        fetcher: ReturnType<typeof Fetcher.for<portPaths>>;
        environment: Environment;
    };
    bilhold: {
        baseUrl: string;
        fetcher: ReturnType<typeof Fetcher.for<bilholdPaths>>;
        environment: Environment;
    };
    carcare: {
        baseUrl: string;
        fetcher: ReturnType<typeof Fetcher.for<carCarePath>>;
        environment: Environment;
    };
    damageappraisal: {
        baseUrl: string;
        fetcher: ReturnType<typeof Fetcher.for<damageAppraisalPaths>>;
        environment: Environment;
    };
    userSelfService: {
        baseUrl: string;
        fetcher: ReturnType<typeof Fetcher.for<userSelfServicePaths>>;
        environment: Environment;
    };
};

type FetcherConfigs = {
    [key in keyof Fetchers]: {
        localEnvironmentOverride: string | undefined;
        middlewares: Middleware[];
    };
};

type FetcherProviderProps = {
    children: ReactNode;
    environment: Environment;
    configs: FetcherConfigs;
};

export function FetchersProvider({
    children,
    environment,
    configs,
}: FetcherProviderProps) {
    const fetchers: Fetchers = {
        port: configurePortFetcher({ ...configs.port, environment }),
        bilhold: configureBilholdFetcher({ ...configs.bilhold, environment }),
        carcare: configureCarCareFetcher({ ...configs.carcare, environment }),
        damageappraisal: configureDamageAppraisalFetcher({
            ...configs.damageappraisal,
            environment,
        }),
        userSelfService: configureUserSelfServiceFetcher({
            ...configs.userSelfService,
            environment,
        }),
    };
    return (
        <FetchersContext.Provider value={fetchers}>
            {children}
        </FetchersContext.Provider>
    );
}

export function useFetchersContext() {
    const fetchers = useContext(FetchersContext);

    if (!fetchers) {
        throw new Error(
            'Fetchers context is undefined. UseFetchersContext must be used within a FetchersProvider'
        );
    }

    return [fetchers] as const;
}
